/* BottomNavbar.css */


.bottom-navbar {
    height: 8vh;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background:#333333;
    background-color: #333333;
    opacity: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
  }

.btm-row{
  background-color: rgb(68, 93, 215);
}

.btm-col {
  height: 8vh;
  background-color: red;
}
  
  .bottom-navbar a {
    color: #f0f0eb;
    text-decoration: none;
  }

  .bottom-navbar a:hover {
    color: rgb(68, 93, 215); /* Change the color on hover */
    
    }
  