* {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
}
html{
        font-size: 62.5%;


}

body{
        background: rgb(34, 33, 36);
        color: rgb(230, 219, 219);

        font-family: 'Poppins', sans-serif;
        height: 100%;


}

.App {
        min-height: 100%;
        display: flex;
        width: 100%;
}

/* styles.css */

.main {
        flex: 20;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

}

.container {
        container-type: inline-size;
        text-align: center;
        overflow: hidden;
      }

      .banner-logo {
        width: 13vh;
        padding-top: 5vh;
      }

      .main-banner {
        color: #BB86FC;
        font-size: 2.5em;
        margin: 0.3em 0;
      }

      .sub-banner {
        font-size: 1.5em;
        color: rgb(236, 223, 223);
      }

      .text-box {
        padding: 20px;
        text-align: center;
      }

      .examples-holder{
        align-items: center;
        padding-bottom: 5vh;
      }
      .example-btn{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgb(233, 222, 222);
        font-size: 1.3em;
        width: 90%;
        max-width: 35vh;
        padding: 0.7em;
        margin: 0.3em;
      }

      /* Container queries for the header */
@container (min-width: 350px) {
        .banner-logo {
          width: 13vh;
          padding-top: 10vh;


        }
        .main-banner {
          font-size: 2.8em;
        }
        .sub-banner {
          font-size: 1.7em;
        }
}

      @container (min-width: 800px) {
        .banner-logo {
          width: 15vh;
          padding-top: 10vh;
        }
        .main-banner {
          font-size: 3.7em;
        }
        .sub-banner {
          font-size: 2em;
        }

      }




.auth-close{
        color: whitesmoke;
        align-self: flex-end;
        cursor: pointer;
}
.auth-back{
        color: whitesmoke;
        cursor: pointer;
        position:fixed;
        left:1em;
}

.auth-back:hover{
        color: #4351a2;
}

.auth-close:hover{
        color: #4351a2;
}



.latex-container {
        align-items: center;
        max-width: 100%; /* Set your desired max-height */
        overflow-x: scroll; /* Enable vertical scrolling */
        padding: 0.5rem;
      }


.account{
      position: absolute;
        color: #181717;
        top: 10rem;
        max-height: 70vh;
        max-width: 100vh;

}

.profile-detail{
        font-size: 1.6rem;
        color: #f0f0eb;
        background-color: #2a2727;
        border-radius: 1rem;

}

.terms-container {
        position: flex;
        flex-direction: row;
        width: 100%;

        align-items: center;
        background-color:#f9f9f9;
        font-size: 1.5rem;

    }

    .terms-content {
        padding: 2rem;
        border-radius: 2rem;
        background-color: #f9f9f9;
        width: 100%;
        max-width: 80rem;
        margin: auto;
    }

    .h-conds {
        color: #333;
        line-height: 3;

    }


    .p-conds {
        color: #555;
        line-height: 1.8;
    }


.logoHeader{
        width: 100%;
        position:static;
        display: flex;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 3rem;
        background-color:rgb(34, 33, 36);

        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-family:'Courier New', Courier, monospace;
        border-bottom: 0.2rem solid rgb(71, 73, 73);



}
.logoHeader>img{
        border: 0.1rem solid rgb(68, 93, 215);
        border-radius: 4rem;
       }

.logoHeader>span{
        padding-left: 0.5rem;
        padding-right: 0rem;
}

.mb-1{
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: wheat;


}

.term-cond{
        position: fixed;
        right: 2rem;
        z-index: 2;

}

.credits{
        position: fixed;
        padding-left: 2rem;
        padding-top: 1rem;
        color: rgb(211, 211, 34);
       align-self: self-start;
       z-index: 2;


}
.dropdown-button-info>.dropdown-toggle {
        border-radius: 50%; /* Make the button circular */
        padding: 0.1rem;    /* Adjust padding as needed */
        width: 3vh;        /* Set the width */
        height: 3vh;       /* Set the height */
        display: flex;
        align-items: center;
        justify-content: center;
}


.dropdown-button-info>.dropdown-toggle::after {
        display: none; /* Remove the default indicator */
    }



.dropdown-item {
        font-size: 1.2rem; /* Adjust the size as needed */


    }

.dropdown-item:hover{
        background-color: rgb(241, 235, 224);
}



.chats {
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-width: 70rem;
        padding-bottom: 15rem;
        z-index: 1;


}

.chatsMobile {
        overflow: hidden;
        width: 100%;
        max-width: 70rem;
        height: 100%;
        padding-bottom: 20rem;
        z-index: 1;
}




.chat {
        padding: 2rem 3rem;
        font-size:  1.5rem;
        display: block;
        align-items: flex-start;
        text-align: justify;
        z-index: 1;

}

.quest {
        margin-top: 5rem;
        margin-bottom: 4rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        font-size: 1.6rem;
        border-bottom: 0.2rem solid rgb(103, 99, 99);
        border-top: 0.2rem solid rgb(103, 99, 99);

}



.answer_text {
        white-space: pre-line;
        line-height: 4rem;
        overflow-x: scroll; /* Enable vertical scrolling */

}

/* Center the buttons in a row */
.button-container {
        margin-top: 3rem;
        padding-top: 3rem;
        padding-bottom: 10rem;
        display:flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Align vertically */
        gap: 4rem; /* Space between buttons */
        border-top: 0.1rem solid rgb(103, 99, 99);

      }

      /* Base button styling */
      .button {
        font-size: 1.5rem;
        font-weight: 600;
        padding: 1.2rem 1.2rem;
        width: 11rem;
        height: 10rem;
        border: none;
        border-radius: 1rem;
        cursor: pointer;
        text-align: center;
        transition: all 0.3s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); /* Default shadow */
        background-color: #242121;

}

      /* "Ask New Question" button styling */
      .ask-new-question {

        border:0.2rem solid #BB86FC; /* Vibrant orange */
        box-shadow: 0 4px 12px rgba(162, 116, 158, 1); /* Orange glow */
      }

      .ask-new-question:hover {
        background-color: #874ecb; /* Darker orange */

        box-shadow: 0 4px 12px rgba(199, 188, 198, 0.4); /* Orange glow */
      }

      .ask-new-question:active {
        transform: scale(0.96); /* Shrink on click */
      }

      /* "Ask Follow-Up Question" button styling */
      .ask-follow-up-question {
        border:.2rem solid #03a9f4; /* Vibrant orange */

        box-shadow: 0 4px 12px rgba(3, 169, 244, 1); /* Blue glow */
      }

      .ask-follow-up-question:hover {
        background-color: #0288d1; /* Darker blue */
        box-shadow: 0 6px 16px rgba(3, 169, 244, 0.6); /* Intensify glow */
      }

      .ask-follow-up-question:active {
        transform: scale(0.96); /* Shrink on click */
      }


.bot {
        background: rgba(28, 30, 58, 1);
        border-radius: 0.5rem;
}

.chatFooter {
        width: 40%;
        position:fixed;
        bottom: 6vh;
        flex-direction: row;
        align-items: center;
        z-index: 2;



    }

.chatFooterMobile {
        position:fixed;
        bottom: 8vh;
        flex-direction: row;
        padding: 1rem;
        width: 100%;
        z-index: 2;




}


.inp {
        width: 100%;
        border: 0.2rem solid  rgb(55, 58, 98);
        border-radius: 3rem;

        background: rgba(28, 30, 58, 1);
        justify-content: center;
        outline: none;
        z-index: 2;



}

.animated-button {
        padding: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
        outline: none;
        border: none;
        border-radius: 2rem;
        color: #d2c4c4;
        background-color:rgba(28, 30, 58, 1);
        transition: color 0.3s ease; /* Add a smooth transition for background color */
      }

      .animated-button.active {
        background-color:rgb(210, 196, 196);

        color: #4351a2; /* Change background color on hover */
      }



#loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        background-color: white;
      }



.mainHeader{
        font-size: 1.5rem;
}

.spinnerHeader{
        font-size: 2rem;
        margin-top: 5rem;
}

.mainAccordion{
        max-width: 60vh;
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 4rem;
        font-size: 1.5rem;
        background-color: #272525;
        color: #272525;
        z-index: 0;




}

.childAccordion{
        width: 100%;
        font-size: 1.5rem;
        background-color: #272525;
        color: #272525;
        z-index: 0;
        margin-top: 2rem;



}

.accordion{
        margin-right: -1.6rem;
        margin-left: -0.3rem;
}

.accordion-button {
        margin: 0;
        font-size: 1.5rem;
        justify-content:end;
        color: whitesmoke;
        background-color: #272525;
        z-index: 0;
        border: none;
        box-shadow: 0 4px 12px rgba(127, 133, 160, 0.4); /* Orange glow */




}
.accordion-item {
        background-color: #242121;
        color: whitesmoke;
        line-height: 1rem;
        margin-bottom: -1rem;
        z-index: 0;


}

.accordion-button:not(.collapsed) {
        background-color: #2a2929;
        color: whitesmoke;
        box-shadow: inset 0 0.2rem 0 rgb(0 0 0 / 13%);
        z-index: 0;



}

.accordion-button::after {
        -webkit-filter: grayscale(1) invert(1);
        filter: grayscale(1) invert(1);

        z-index: 0;

}
.card {
        background-color:#2e2b3c;
        margin-bottom: 2rem;
        margin-top: 1rem;
        text-align: start;
        white-space: pre-wrap;
        line-height: 3rem;
        color: whitesmoke;
        box-shadow: 0.1rem 0.2rem 0.2rem 0.1rem rgba(68, 86, 165, 0.4); /* Orange glow */


}



.isnput {
        width: calc(100% - 3rem);
}


.inp>input, .send {
        background: transparent;
        border: none;
}




.auth-modal .modal-content {
        background-color:#2a2727;
        color: #181717;
        height: 65vh;
        max-height: 90vh;
        max-width: 90vh;
        position: absolute;
        font-size: 1.6vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2vh;
        border: 0.1rem solid #d5dce2;
        scroll-behavior: auto;



      }


.cancel_modal .modal-content {
        background-color:#2a2727;
        color: #181717;
        height: 25vh;
        width: 50vh;
        max-height: 60vh;
        max-width: 90vh;
        position: absolute;
        font-size: 1.6vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2vh;
        border: 0.1rem solid #d5dce2;
        scroll-behavior: auto;


      }
.subs_modal .modal-content {
        background-color:#2a2727;
        color: #181717;
        height: 90vh;
        max-height: 90vh;
        max-width: 90vh;
        position: absolute;
        font-size: 1.6vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2vh;
        border: 0.1rem solid #d5dce2;
        scroll-behavior: auto;


      }

.crop-modal .modal-content {
        background-color:#2a2727;
        color: #181717;
        font-size: 1.6vh;
        border-radius: 2vh;
        border: 0.1rem solid #d5dce2;


      }

.modal_login_header{
        display: flex;
        flex-direction:row;
        justify-content: flex-end;
        border: none;

    }

.modal_subscribe_header{
        display: flex;
        flex-direction:row;
        justify-content: flex-end;
        border: none;

}




.btn-xxl {
        font-size: 1.6rem !important;
        width: 100%;
        border-radius: 2rem;
}

.signPart {
        justify-content: center;
        display: flex;


}
.btn-sign {
        font-size: 1.6rem !important;
        width: 50%;
        border-radius: 1rem;
        background-color: #5A4BFF;
        border-color:#5A4BFF;
        margin: 2rem auto;


}

.toggle_button_group {
        background-color: #d5dce2;
        padding: 0.1rem;
        border: 0.1rem solid #d5dce2;
        border-radius: 2rem;
        margin-bottom: -3rem;
        z-index: 2;

}

.price-value {
        font-size: 4rem;
        font-weight: bold;
        color: #007bff; /* Bootstrap primary color */
    }

    .price-duration {
        font-size: 1rem;
        color:#2a2727; /* Bootstrap secondary color */
    }

.tick-span {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #007bff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
}

.toggle_btn {
        border: 0.1rem solid #abb8c6;

        border-radius: 2rem;
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
}
.price_card{
        max-height: 100vh;
        padding-top: 3rem;
        text-align: center;
        color: #181717;
        background-color: #d5dce2;
        border: 0.1rem solid #d5dce2;
        border-radius: 2rem;

}

.attr-card {
        border-radius: 1rem;
        border: 0.2rem solid #bcc3c9;
        color: #181717;

        background-color: #e8ecee;
        margin: 0.6rem;
        transition: transform 0.2s;

}

.attr-card:hover {
        transform: scale(1.05);
      }

.subscribe-btn{
        border: 0.1rem solid #7098bb;

}

.cropper-container {
        max-height: 100vh; /* Ensure the cropper fits within the modal height */
        max-width: 100%; /* Ensure the cropper fits within the modal width */
        overflow: hidden; /* Hide overflow */
      }
/* Custom CSS for better touch interactions */
.cropper-crop-box, .cropper-view-box {
        border-radius: 4px; /* Rounded corners for the crop box */
      }


.cropper-crop-box .cropper-line, .cropper-crop-box .cropper-point {
        width: 100px; /* Increase the width of the edges */
        height: 100px; /* Increase the height of the edges */
}

.cropper-point{
        margin-bottom: 0rem;
}

.cropper-line.line-e{
        width: 5px;
}
.cropper-crop-box .cropper-point {
        width: 1.5rem !important; /* Ensure the bottom-right point has the same size */
        height: 1.5rem !important; /* Ensure the bottom-right point has the same size */
        opacity: 1 !important;



}

.cropper-point.point-w {
        width: 8px !important; /* Ensure the bottom-right point has the same size */
        height: 20px !important; /* Ensure the bottom-right point has the same size */
        background-color: #007bff;

}
.cropper-point.point-e {
        width: 8px !important; /* Ensure the bottom-right point has the same size */
        height: 20px !important; /* Ensure the bottom-right point has the same size */
        background-color: #007bff;
}
.cropper-point.point-s {
        height: 8px !important; /* Ensure the bottom-right point has the same size */
        width: 20px !important; /* Ensure the bottom-right point has the same size */
        background-color: #007bff;
}
.cropper-point.point-n {
        height: 8px !important; /* Ensure the bottom-right point has the same size */
        width: 20px !important; /* Ensure the bottom-right point has the same size */
        background-color: #007bff;
}


.cropper-point.point-se {
        width: 24px !important; /* Ensure the bottom-right point has the same size */
        height: 24px !important; /* Ensure the bottom-right point has the same size */
        background-color: rgba(28, 27, 27, 0);
        background-image: url("assets/rightBot.png");
        opacity: 0;



}
.cropper-point.point-sw {
        width: 24px !important; /* Ensure the bottom-right point has the same size */
        height: 24px !important; /* Ensure the bottom-right point has the same size */
        background-color: rgba(28, 27, 27, 0);
        background-image: url("assets/leftBotB.png");



}

.cropper-point.point-nw {
        width: 24px !important; /* Ensure the bottom-right point has the same size */
        height: 24px !important; /* Ensure the bottom-right point has the same size */
        background-color: rgba(28, 27, 27, 0);
        background-image: url("assets/leftTop.png");



}

.cropper-point.point-ne {
        width: 24px !important; /* Ensure the bottom-right point has the same size */
        height: 24px !important; /* Ensure the bottom-right point has the same size */
        background-color: rgba(28, 27, 27, 0);
        background-image: url("assets/rightTop.png");



}




.latex-renderer {
        line-height: 1.5; /* Adjust line height as needed */
      }

      /* Optional: Additional styling to improve appearance */
      .latex-renderer .katex {
        font-size: 1.2em; /* Adjust font size as needed */
      }

      .image-container {
        position: relative;
        display: inline-block;
      }

      .spinner {
        position: absolute;
        color: red;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .image {
        display: block;
        width: 100%;
        height: auto;
      }

.page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        padding: 5rem;
        background-color: #f8f9fa;
        text-align: center;
        position: relative;
      }

      .back-button {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: transparent;
        border: none;
        color: #007bff;
        font-size: 16px;
        cursor: pointer;
      }

      .content {
        background-color: #ffffff;
        padding: 5rem;
        border-radius: 2rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
        width: 70vh; /* Make sure it doesn't overflow */
      }

      .success-page h1 {
        color: #28a745;
      }

      .cancel-page h1 {
        color: #dc3545;
      }

      .content p {
        margin-top: 2rem;
        font-size: 1.3rem;
        color: #6c757d;
}

.blinking-text {
        display: inline-block;
        padding: 1rem;
        width: 10rem; /* Optional: Add some padding inside the border */
        border: 0.1rem solid #333; /* Adjust the border color and width as needed */
        border-radius: 1rem;
      }

.blinking-content {
        display: inline-block;
        animation: blink 1.5s infinite; /* Text blinking */
}

@keyframes blink{
        0% { opacity: 0; }
        33% { opacity: 0.33; }
        66% { opacity: 0.66; }
        100% { opacity: 1; }
}

.blinking-dots::after {
        content: '';
        display: inline-block;
        animation: dots 1.5s steps(3, end) infinite; /* Three dots animation */
}

@keyframes dots {
        0% { content: ''; }
        33% { content: '.'; }
        66% { content: '..'; }
        100% { content: '...'; }
}

.math-keyboard {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      .math-keyboard button {
        background-color: #5a4be7;
        color: white;
        border: none;
        padding: 10px;
        margin: 5px;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 4px;
      }

      .math-keyboard button:hover {
        background-color: #3d36b1;
      }

      .mathquill-editor {
        color: white !important; /* Ensure text is white */
        font-size: 2.5rem !important; /* Font size */
        background-color: rgba(224, 155, 155, 0.5); /* Slightly dark background for contrast */
        padding: 10px; /* Add padding for better spacing */
        caret-color: yellow !important; /* Cursor color */
        border-color: #007bff;

}

      .mathquill-editor .mq-root {
        caret-color: yellow !important; /* Cursor color */
      }

      /* Ensure the cursor is visible */
      .mathquill-editor .mq-root:focus {
        outline: none; /* Remove focus outline */
        caret-color: yellow !important; /* Cursor color */

      }

.crp-img-container {
        height:6rem;
        width:6rem;
        overflow:hidden;
        border:0.1rem solid #c9b5b5;
        border-radius:1rem;
        display:flex;
        justify-content:center;
        align-items:center;
        margin-right: 2rem;
}
.inp-close-btn {
        position: absolute;
        top: 0.2rem;
        left: 0.4rem;
        background-color: rgba(28, 30, 58, 1);
        color: rgb(224, 207, 222);
        border: none;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 2rem;
      }

      .question-grid {
        margin-top: 15rem;
        width: 100%;
        display: flex;
        gap: 1rem; /* Adds space between cards */
        padding: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); /* Ensures cards are spaced and centered */
        justify-content: center; /* Centers the grid horizontally */
        align-items: center; /* Centers the grid vertically */
      }

      .question-card {
        position: relative;
        width: 20rem;
        height: 15rem;
        padding: 1rem;
        border: 0.1rem solid #ccc;
        border-radius: 0.5rem;
        box-shadow: 0 0px 4px rgb(194, 181, 193); /* Orange glow */
        text-align: start;
        cursor: pointer;
        transition: transform 0.2s;
      }

      .question-card:hover {
        transform: scale(1.05);

        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      }

      .topic-title {
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        font-size: 1.3rem;
        font-weight: bold;
        padding: 0.2rem 0.5rem;
        border-radius: 0.25rem;
      }

      .question-text {
        margin-top: 2rem;
        font-size: 1.3rem;
      }


.signup-banner {
        z-index: 3;
        position: fixed;

  display: inline-block;
  background: linear-gradient(120deg, #3b82f6, #b910a8);
  color: white;
  border-radius: 8px;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  text-align: center;
  margin-top: 4rem ;
  box-shadow: 0 2px 6px rgb(65, 68, 149); /* Orange glow */
}

.signup-banner p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.signup-link {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.3rem;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.signup-link:hover {
  color: #d1fae5;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
}


