.Contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color:#f4f4f9;
    font-size: 1.5rem;
    
}
  .Contact-header{
    text-align: center;
    width: 100%;
    max-width: 80rem;
    margin: auto;
    color:#555;
    padding: 1rem;
    border-bottom: 0.2rem solid rgb(155, 159, 159);


  }
  .Contact-header h1{

    font-size: 2.5rem;
  }

  .ContactUs {
    text-align: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 80rem;
    margin: auto;

  }
  
  
  .ContactUs-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5rem;
    flex-wrap: wrap;
  }
  
  .Profile {
    flex: 1;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .Profile-pic {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
    border: 0.2rem solid #ccc;
    margin-bottom: 1rem;
  }

  .linkedin-pic{
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }

  .Profile h2 {
    margin-bottom: 1rem;
    color: #555;
    font-size: 1.5rem;
    font-family: 'Courier New', Courier, monospace;

  }
  
  .Profile h1 {
    color: #555;
    font-size: 1.8rem;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;


  }
  
  .Profile a {
    display: inline-block;
    color: #0e76a8;
    text-decoration: none;
    margin-bottom: 1rem;

  }
  
  .Profile p {
    margin: 0;
    color: #555;
    font-family: 'Courier New', Courier, monospace;

  }
  
  .Company-info {
    flex: 1;
    text-align: left;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .Company-info h3 {
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  }
  
  .Company-info p {
    margin: 5px 0;
    color: #555;
    font-family: 'Courier New', Courier, monospace;

  }
  
  @media (max-width: 600px) {
    .ContactUs-section {
      flex-direction: column;
      align-items: center;
    }
    
    .Company-info {
      text-align: center;
    }
  }
  