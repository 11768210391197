

.sidebar {
height: 100vh;
background-color: #2a2929;
color: #fff;
display:flex;
flex-direction: column;
position: -webkit-sticky;
position: sticky;
top: 0;
flex-grow: 0.3;
padding: 0;
margin: 0;
transition: all 0.3s ease;

}

.sidebar.expanded {
        flex-grow: 0.6;




}

.logo{
        flex: 1;
        align-self: center;
        margin-top: 2rem;
        padding-bottom: 1.5rem;
        font-size: 1.3rem;
        font-family:'Courier New', Courier, monospace;
        border-bottom: 0.2rem solid rgb(71, 73, 73);



}
.logo>img{
        border: 0.1rem solid #445dd7;
        border-radius: 4rem;
       }

.logo>span{
        padding-left: 0.5rem;

}

.menu {
align-items: center;
display: flex;
flex-direction: column;
margin-top: 3rem;
flex:7;


}

.menu a {
text-decoration: none;
color: #9d9898;
padding-top: 30px;
margin-bottom: 10px;
justify-content: center;
align-items: center;

}

.menu a span{
  margin-left: 0.7rem;
}
.menu a:hover {
color: rgb(68, 93, 215); /* Change the color on hover */

}


.menu a.active {
color: #f0f0eb; /* Change the color when the link is active/clicked */
}
.menuItemText{
text-decoration: none;
display:flex;
font-size: 1.5rem;
cursor: pointer;
align-items: center;


}

.toogle-container{
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        flex:4;
}
.toggle-button {
align-self:self-end;

/* Add margin to the right side */
padding: 5px;
background-color: #2a2929;
color: #fff;
border: none;
cursor: pointer;
transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-button:hover {
color:rgb(97, 119, 226); /* Change the background color on hover */
}

.toggle-button:active {
transform: scale(0.95); /* Add a slight scale effect on click */
}

button {
margin-top: 10px;
padding: 10px;
background-color: #555;
color: #fff;
border: none;
cursor: pointer;
}

.account-container {
background-color: #2d2b2b;
padding: 2rem;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
width: 60%;
margin-bottom: 5rem;
}

.account-title {
color: #e4dede;
margin-bottom: 2rem;
}

.profile-detail {
padding: 2rem;
font-size: 1.4rem;
border-bottom: 2px solid rgb(56, 54, 54);
display: flex;

}

.profile-detail :not(strong) {
        display: flex;
}

.profile-detail strong {
margin-right: 2rem;
}




.brand {
        font-size: 2rem;
}

.bottom-menu {
        flex: 1;
        align-self: center;


}




